* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Switzer', sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
}


.show-menu {
  display: block;
}

.menu-background {
  background-color: white;
  height: 95vh;
  width: 100%;
}

.bg-color {
  background-color: white;
}


.marquee-section {
  margin-top: -120px;
}


.hero-area {
  clip-path: polygon(0% 0%, 100% 0%, 100% 92%, 0% 100%);
}



.marquee-img {
  animation: marquee-hero 50s linear infinite;
}

@keyframes marquee-hero {

  from {
    transform: translateX(-80%);
  }

  to {
    transform: translateX(100%);
  }
}

.slick-next {
  margin-right: 80px !important;
}

.slick-prev {
  margin-left: 80px !important;
  z-index: 999;
}

.slick-next::before,
.slick-prev::before {

  font-size: 40px !important;
}

.slick-dots li button:before {

  font-size: 12px !important;
}


.slide-img {
  filter: blur(10px);
}


.comment-clip {
  clip-path: polygon(0% 0%, 100% 0%, 100% 67%, 50% 82%, 0% 67%);
}


.powering {
  background-position: 'center -250px',
}


@media screen and (max-width:1024px) {
  .powering {
    background-position: 'center 0px',
  }
}

@media screen and (max-width:768px) {

  .hero-area {
    clip-path: polygon(0% 0%, 100% 0%, 100% 98%, 0% 100%);
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .comment-clip {
    clip-path: polygon(0% 0%, 100% 0%, 100% 77%, 50% 85%, 0% 77%);
  }


}