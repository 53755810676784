@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;
}

#my-tooltip {
  width: 300px !important;
  text-align: center !important;
}

a:hover {
    color: #747bff;
  }
  
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    margin-top: 10px;
    z-index: 99998;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
      border-radius: 10px;
    }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  
  /* a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
  }
  a:hover {
    color: #535bf2;
  }
  
  h1 {
    font-size: 3.2em;
    line-height: 1.1;
  }
  
  button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
  }
  button:hover {
    border-color: #646cff;
  }
  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  } */
  
  
  .roboto-thin {
    font-family: "Roboto", serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-light {
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-regular {
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-medium {
    font-family: "Roboto", serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-bold {
    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-black {
    font-family: "Roboto", serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", serif;
    font-weight: 900;
    font-style: italic;
  }
  
  .moulpali-regular {
    font-family: "Moulpali", serif;
    font-weight: 400;
    font-style: normal;
  }
  
.disable-drag {
  -webkit-user-drag: none;
}

/* Burger Icon */
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 21px;
    height: 21px;
    right: 18px;
    top: 9px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
    height: 10% !important;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .menu-item {
    margin-bottom: 10px;
  }
  .bm-menu-wrap {
    position: fixed;
    top: 0px;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  
  
  .bm-burger-button > span > span:first-child {
  width: 100%;
  }
  
  .bm-burger-button > span > span:nth-child(2) {
    /* Styles for the second child */
    margin-left: 3.7px !important;
    top: 30% !important;
    width: 80%;
  }
  
  .bm-burger-button > span > span:nth-child(3) {
    /* Styles for the third child */
    margin-left: 7px !important;
    top: 60% !important;
    width: 60%;
  }
  
  @media (min-width: 1024px) {
    .bm-burger-button {
      display: none;
    }
  }
  